<template>
  <div>
    <toolbar/>
    
      <!-- <div class="packhai-container">
        <router-view/>
      </div> -->
      <div v-if="$vuetify.breakpoint.xs">
        <div class="hidden-sm-and-up packhai-container-mobile">
          <router-view/>
        </div>
      </div>
      <v-main v-else>
        <v-container class="hidden-xs-only ">
          <router-view/>
        </v-container>
      </v-main>
      
    

      <!-- <main>
          <div class=" hidden-xs-only packhai-container">
            <router-view/>
          </div>
        </main> -->
    
  </div>
</template>

<script>
  import Toolbar from '@/website/components/Toolbar'
  export default {
    data () {
      return { show: false }
    },
    components: {
      Toolbar
    },
  }
</script>
