<template>
  <div>
    <v-app-bar fixed elevation="1" height="65px">
      <v-row v-if="Window == 'mobile' || size == 'md'">
        <v-btn
          @click.stop="mobile_menu = !mobile_menu"
          outlined
          fab
          small
          class="color-black"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <a v-if="Window == 'mobile' || size == 'md'" @click="goto(menu_dashboard.path)">
          <img
            v-if="!isSubscription"
            class="ml-4"
            src="@/assets/logo_color_packhai_warehouse.png"
            width="170px"
          />
          <img
            v-else-if="branch.logo_Branch != null"
            :src="branch.logo_Branch"
            class="ml-4"
            width="170px"
          />
          <img v-else :src="branch.logo" class="ml-4" width="170px" />
          <!-- <img src="@/assets/logo_color_packhai_warehouse.png" width="180" /> -->
        </a>
      </v-row>

      <v-spacer></v-spacer>
      <div v-if="Window == 'pc'">
        <a @click="goto(menu_branch_balance.path)"  v-if="staffBranchId!=1629">
          <span> เครดิตคงเหลือ : </span>
          <span class="pl-1 color-red" v-if="balance < 1">{{
            " " + formatMoney(balance)
          }}</span>
          <span class="pl-1 color-green" v-else>{{ " " + formatMoney(balance) }}</span>
          <span class="pl-1"> บาท </span>
        </a>
        <div v-if="isSubscription">
          จำนวนออเดอร์ที่แพ็คได้
          <span v-if="branch.subscriptionSize != 'XL'"
            ><span class="color-green"> {{ formatMoney(branch.orderCredit) }}</span>
            ออเดอร์
          </span>
          <span v-else class="color-green"> ไม่จำกัด </span>
        </div>
        <div v-if="isSubscription">
          ใช้งานได้ถึงวันที่
          <span v-if="branch.endSubscriptionDate != null">{{
            formatDatetime(branch.endSubscriptionDate)
          }}</span>
        </div>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      app
      permanent
      :mini-variant.sync="drawers"
      class="hidden-xs-only"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-item v-if="drawers == false">
            <img
              v-if="!isSubscription"
              src="@/assets/logo_color_packhai_warehouse.png"
              style="width: 170px"
            />
            <img
              v-else
              :src="branch.logo_Branch == null ? branch.logo : branch.logo_Branch"
              width="170px"
            />
          </v-item>
          <v-item v-else>
            <v-icon>mdi-menu</v-icon>
          </v-item>

          <v-spacer></v-spacer>
          <v-btn @click.stop="drawers = !drawers" outlined fab small class="color-black">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-list-item>
      </template>
      <v-divider></v-divider>

      <v-list shaped>
        <v-list-item v-if="drawers == false">
          <v-list-item-content>
            <v-list-item-title>
              <b>{{ branch_name }} </b
              ><span v-if="isSubscription == true && branch_check_name_have_sub == false"
                >(SUB)</span
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pa-0" @click="goto(menu_dashboard.path)">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menu_dashboard.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="mdi-cart" color="none">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> ออเดอร์ </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menu_order"
              :key="index"
              @click="goto(item.path)"
            >
              <li>{{ item.title }}</li>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-group prepend-icon="mdi-chart-bar-stacked" color="none">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> สต๊อก </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menu_stock"
              :key="index"
              @click="goto(item.path)"
            >
              <li>{{ item.title }}</li>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-item class="pa-0" @click="goto(menu_manageshop.path)" v-if="staffBranchId!=1629">
          <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menu_manageshop.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="mdi-credit-card-multiple" color="none" v-if="staffBranchId!=1629">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> การเงิน(ร้านค้า) </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menu_finance"
              :key="index"
              @click="goto(item.path)"
            >
              <li>{{ item.title }}</li>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-group prepend-icon="mdi-wallet" color="none" v-if="staffBranchId!=1629">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> การเงิน(สาขา) </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menu_finance_branch"
              :key="index"
              @click="goto(item.path)"
            >
              <li>{{ item.title }}</li>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-item   
          v-if="permission.PageReport &&staffBranchId!=1629"
          class="pa-0"
          @click="goto(menu_report.path)"
        >
          <v-list-item-icon>
            <v-icon>mdi-chart-bar-stacked</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menu_report.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item class="pa-0" @click="goto(menu_user.path)">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menu_user.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-group prepend-icon="mdi-account" color="none" v-if="staffBranchId!=1629">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> ผู้ใช้ </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menu_users"
              :key="index"
              @click="goto(item.path)"
            >
              <li>{{ item.title }}</li>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-item class="pa-0" @click="goto(menu_delivery_note.path)"  v-if="staffBranchId!=1629">
          <v-list-item-icon>
            <v-icon>mdi-av-timer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menu_delivery_note.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="mdi-cog" color="none" v-if="staffBranchId!=1629">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> ตั้งค่า </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menu_setting"
              :key="index"
              @click="goto(item.path)"
            >
              <li>{{ item.title }}</li>
            </v-list-item>
          </v-list>
        </v-list-group>
      </v-list>

      <v-list-item v-if="isSubscription" class="pa-0" @click="goto(menu_package.path)">
        <v-list-item-icon>
          <v-icon>mdi-package</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ menu_package.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-slot:append>
        <a v-if="Window == 'mobile'" class="px-3" @click="goto(menu_branch_balance.path)">
          <span> เครดิตคงเหลือ : </span>
          <span class="pl-1 color-red" v-if="balance < 1">{{
            " " + formatMoney(balance)
          }}</span>
          <span class="pl-1 color-green" v-else>{{ " " + formatMoney(balance) }}</span>
          <span class="pl-1"> บาท </span>
        </a>
        <div v-if="isSubscription && Window == 'mobile'" class="px-3">
          จำนวนออเดอร์ที่แพ็คได้
          <span v-if="branch.subscriptionSize != 'XL'" class="color-green">{{
            formatMoney(branch.orderCredit)
          }}</span>
          <span v-else class="color-green"> ไม่จำกัด </span>
        </div>
        <div v-if="isSubscription && Window == 'mobile'" class="px-3">
          ใช้งานได้ถึงวันที่
          <span v-if="branch.endSubscriptionDate != null">{{
            formatDatetime(branch.endSubscriptionDate)
          }}</span>
        </div>
        <v-divider />
        <v-list shaped>
          <v-list-item class="pa-0" @click="btn_logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> ออกจากระบบ </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-toolbar-items class="hidden-sm-and-up">
      <v-navigation-drawer app v-model="mobile_menu" temporary>
        <v-list>
          <v-list-item>
            <v-item>
              <img
                v-if="!isSubscription"
                src="@/assets/logo_color_packhai_warehouse.png"
                style="width: 170px"
              />
              <img v-else :src="branch.logo" width="170px" />
            </v-item>
            <v-spacer></v-spacer>
            <v-item>
              <v-btn
                @click.stop="mobile_menu = !mobile_menu"
                outlined
                fab
                small
                class="color-black"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </v-item>
          </v-list-item>
        </v-list>

        <v-list shaped>
          <v-list-item v-if="Window == 'mobile'">
            <v-list-item-content>
              <v-list-item-title>
                <b>{{ branch_name }} </b
                ><span
                  v-if="isSubscription == true && branch_check_name_have_sub == false"
                  >(SUB)</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0" @click="goto(menu_dashboard.path)">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ menu_dashboard.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group prepend-icon="mdi-cart" color="none">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> ออเดอร์ </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menu_order"
                :key="index"
                @click="goto(item.path)"
              >
                <li>{{ item.title }}</li>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-group prepend-icon="mdi-chart-bar-stacked" color="none">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> สต๊อก </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menu_stock"
                :key="index"
                @click="goto(item.path)"
              >
                <li>{{ item.title }}</li>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-item class="pa-0" @click="goto(menu_manageshop.path)">
            <v-list-item-icon>
              <v-icon>mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ menu_manageshop.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group prepend-icon="mdi-credit-card-multiple" color="none">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> การเงิน(ร้านค้า) </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menu_finance"
                :key="index"
                @click="goto(item.path)"
              >
                <li>{{ item.title }}</li>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-group prepend-icon="mdi-wallet" color="none">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> การเงิน(สาขา) </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menu_finance_branch"
                :key="index"
                @click="goto(item.path)"
              >
                <li>{{ item.title }}</li>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-item
            v-if="permission.PageReport"
            class="pa-0"
            @click="goto(menu_report.path)"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-bar-stacked</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ menu_report.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item class="pa-0" @click="goto(menu_user.path)">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menu_user.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

          <v-list-group prepend-icon="mdi-account" color="none">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> ผู้ใช้ </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menu_users"
                :key="index"
                @click="goto(item.path)"
              >
                <li>{{ item.title }}</li>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-item class="pa-0" @click="goto(menu_delivery_note.path)">
            <v-list-item-icon>
              <v-icon>mdi-av-timer</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ menu_delivery_note.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group prepend-icon="mdi-cog" color="none">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> ตั้งค่า </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menu_setting"
                :key="index"
                @click="goto(item.path)"
              >
                <li>{{ item.title }}</li>
              </v-list-item>
            </v-list>
          </v-list-group>
        </v-list>

        <v-list-item v-if="isSubscription" class="pa-0" @click="goto(menu_package.path)">
          <v-list-item-icon>
            <v-icon>mdi-package</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menu_package.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-slot:append>
          <a
            v-if="Window == 'mobile'"
            class="px-3"
            @click="goto(menu_branch_balance.path)"
          >
            <span> เครดิตคงเหลือ : </span>
            <span class="pl-1 color-red" v-if="balance < 1">{{
              " " + formatMoney(balance)
            }}</span>
            <span class="pl-1 color-green" v-else>{{ " " + formatMoney(balance) }}</span>
            <span class="pl-1"> บาท </span>
          </a>
          <div v-if="isSubscription && Window == 'mobile'" class="px-3">
            จำนวนออเดอร์ที่แพ็คได้
            <span v-if="branch.subscriptionSize != 'XL'" class="color-green">{{
              formatMoney(branch.orderCredit)
            }}</span>
            <span v-else class="color-green"> ไม่จำกัด </span>
          </div>
          <div v-if="isSubscription && Window == 'mobile'" class="px-3">
            ใช้งานได้ถึงวันที่
            <span v-if="branch.endSubscriptionDate != null">{{
              formatDatetime(branch.endSubscriptionDate)
            }}</span>
          </div>
          <v-divider />
          <v-list shaped>
            <v-list-item class="pa-0" @click="btn_logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> ออกจากระบบ </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
    </v-toolbar-items>

    <!-- เหลือเวลาบริการ -->
    <v-dialog v-model="dialogSub" max-width="300" persistent>
      <v-card elevation="1" class="ma-0">
        <v-card-title class="pa-0">
          <v-spacer />
          <v-btn icon @click="dialogSub = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img src="@/assets/logo_color_packhai_subscription.png" width="100%" />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" class="text-center py-4 color-black-navigation">
              <span class="fonttitleform">ใช้บริการได้อีก {{ remaindate }} วัน</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block depressed class="btn-login" @click="gotopackage()">
                ซื้อแพ็คเกจ
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { branchService_dotnet, stockService_dotnet } from "@/website/global";
import { formatMoney, formatDatetime, remainDateCheck } from "@/website/global_function";
export default {
  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    drawers: false,
    mobile_menu: false,
    mini: false,
    balance: 0,
    branch: null,
    isSubscription: false,
    branchLogo: null,

    menu_branch_balance: { title: "", path: "/branch_balance" },
    menu_package: { title: "แพ็คเกจ", path: "/subscription/package" },
    menu_dashboard: { title: "หน้าแรก", path: "/dashboard-statistics" },
    menu_order: [
      { title: "ออเดอร์ทั้งหมด", path: "/order" },
      { title: "ออเดอร์รอดำเนินการ", path: "/product-pack-list" },
      { title: "อัพเดท Tracking", path: "/update_tracking" },
      { title: "เช็คสถานะพัสดุ", path: "/orderStatus" },
      // { title: "ตรวจสอบออเดอร์", path: "/check-order" },
    ],
    menu_stock: [
      { title: "ข้อมูลสินค้า", path: "/my-product" },
      { title: "สต๊อกสินค้า", path: "/my-stock" },
      { title: "นำเข้า/นำออก/โอนย้ายสินค้า", path: "/transfer-list" },
      { title: "การใช้พื้นที่", path: "/shop-space" },
      { title: "Location", path: "/location-manage" },
      { title: "ล็อตสินค้า", path: "/lot-stock" },
      { title: "รายงานการเช็คสต๊อก", path: "/report-check-stock" },
      { title: "ภาพรวมการเดินสต๊อก", path: "/stock-report" },
    ],

    menu_manageshop: { title: "ร้านค้า", path: "/manageshop" },
    menu_finance: [
      // { title: 'ใบแจ้งหนี้', path: '/finance' },
      { title: "สรุปยอด และ ตัดเงินร้านค้า", path: "/create-invoice" },
      { title: "สร้างใบแจ้งหนี้", path: "/create-group-bill" },
      { title: "สร้างใบแจ้งหนี้อื่นๆ", path: "/create-bill" },
      { title: "บิลทั้งหมด", path: "/all-invoice" },
      { title: "รายการร้านค้าแจ้งโอนเงิน", path: "/topup" },
      { title: "COD Report", path: "/cod-report" },
      { title: "แจ้งถอน COD", path: "/withdraw-cod" },
    ],
    menu_finance_branch: [
      { title: "รายการเดินเครดิต", path: "/branch_balance" },
      { title: "แจ้งเติมเครดิต", path: "/branch_topup" },
      { title: "ใบแจ้งหนี้", path: "/branch_invoice" },
      // { title: 'ค่าใช้จ่าย', path: '/expenses' },
    ],
    menu_report: { title: "รายงาน", path: "/report-order" },
    menu_users: [
      { title: "จัดการผู้ใช้", path: "/manageuser" },
      { title: "สถิติ", path: "/user-statistics" },
    ],
    menu_delivery_note: { title: "ใบส่งสินค้า", path: "/delivery-note" },
    menu_setting: [
      { title: "ตั้งค่าทั่วไป", path: "/setting" },
      //{ title: "ตั้งค่ากล่อง", path: "/postbox" },
      // { title: 'ค่าแพ็คสินค้า', path: '/packcostexpress' },
      { title: "ค่าขนส่งมาตรฐาน", path: "/defaultexpress" },
    ],

    branch_name: null,
    branch_check_name_have_sub: false,
    staffBranchId : parseInt(localStorage.getItem("Branch_StaffID")),
    // size จอ
    size: "lg",
    window: {
      width: 0,
      height: 0,
    },

    // ขนาดจอ
    Window: "pc",

    // Subscription
    dialogSub: false,
    remaindate: null,
    toDayFirstLogin: false,
  }),
  watch: {
    size() {
      this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    //this.drawers = this.size == "xs" || this.size == "sm" ? false : true;
    this.drawers = false;
    localStorage.setItem(
      "Window",
      this.size == "xs" || this.size == "sm" ? "mobile" : "pc"
    );
    localStorage.setItem("PageSize", this.size);
    localStorage.setItem("PageWidth", this.window.width);
    localStorage.setItem("PageHeight", this.window.height);

    this.permission = JSON.parse(localStorage.getItem("permission"));
    this.toDayFirstLogin = localStorage.getItem("Branch_ToDayFirstLogin");
    if (this.toDayFirstLogin == "0") {
      this.toDayFirstLogin = false;
    }
    if (this.toDayFirstLogin == "1") {
      this.toDayFirstLogin = true;
    }

    await this.get_balance();
    await this.get_ViewType();
  },
  methods: {
    gotopackage() {
      this.dialogSub = false;
      this.$router.push("/subscription/package");
    },
    async get_balance() {
      try {
        let response = await axios.post(
          branchService_dotnet + "Branch/get-branch-single",
          {
            branchId: localStorage.getItem("Branch_BranchID"),
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          this.branch = response.data.branch;
          this.isSubscription = this.branch.isSubscription;
          this.balance = this.branch.balance;
          this.branch_name = this.branch.name;

          // หาคำว่า SUB
          if (this.branch.isSubscription == true) {
            let result_check_sub = this.branch_name.includes("SUB");
            this.branch_check_name_have_sub = result_check_sub;
          }

          // subscription
          if (this.isSubscription) {
            this.remaindate = this.remainDateCheck(this.branch.endSubscriptionDate);
            if (this.remaindate <= 0) {
              this.$router.push("/gotopackage");
            } else if (this.branch.orderCredit < 0) {
              this.$router.push("/gotopackage");
            } else if (
              this.remaindate > 0 &&
              this.remaindate <= 7 &&
              this.toDayFirstLogin
            ) {
              this.dialogSub = true;
              this.toDayFirstLogin = false;
              localStorage.setItem(
                "Branch_ToDayFirstLogin",
                this.toDayFirstLogin ? 1 : 0
              );
            }
          }
        } else {
          this.balance = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.btn_logout();
        }
      }
    },

    async GetBranchLogo() {
      let branchid = localStorage.getItem("Branch_BranchID");
      let response = await axios.post(
        branchService_dotnet + "Branch/get-branch-info?BranchID=" + branchid,
        {},
        { headers: this.header_token }
      );
      if (response.status == 204 || response.status == 200) {
        if (response.data != "" && response.data != null) {
          if (response.data.logoAWB != null && response.data.logoAWB != "")
            this.branchLogo = response.data.logoAWB;
        }
      }
    },
    goto(item) {
      this.get_balance();
      this.get_ViewType();
      this.$router.push(item);
    },
    closedrawers() {
      this.drawers = false;
    },
    btn_logout() {
      localStorage.removeItem("Branch_BranchID");
      localStorage.removeItem("Branch_StaffID");
      localStorage.removeItem("ViewType");
      this.closedrawers();
      this.$router.push("/login");
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },
    async get_ViewType() {
      await axios
        .post(
          stockService_dotnet + "Stock/view-type",
          {
            branchId: localStorage.getItem("Branch_BranchID"),
            staffBranchId: localStorage.getItem("Branch_StaffID"),
          },
          { headers: this.header_token }
        )
        .then((res) => {
          res.data.viewtype != null
            ? localStorage.setItem("ViewType", res.data.viewtype)
            : "";
        })
        .catch((error) => {});
    },
    formatMoney,
    formatDatetime,
    remainDateCheck,
  },
};
</script>

<style scoped>
>>> .v-list-group div {
  padding: 0px;
}
>>> .v-list-group__items div div {
  padding-left: 16px;
  padding-right: 0px;
}
</style>
